import './styles.css';
import React, { Suspense, useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, Environment, OrbitControls } from '@react-three/drei';
import { MeshStandardMaterial } from 'three';

function App() {
  const models = [
    'models02/s10-r55-e19-s1117.glb',
    'models02/s20-r210-e51-s1265.glb',
    'models02/s22-r253-e46-s655.glb',
    'models02/s26-r351-e36-s1328.glb',
    'models02/s27-r378-e42-s1620.glb',
    'models02/s28-r406-e12-s1429.glb',
  ];

  function Model({ url }) {
    const gltf = useGLTF(url);
    const meshRef = useRef();

    const spheres = gltf.nodes; // Assuming the spheres are the first child of the scene
    console.log(spheres);

    // Hide all spheres initially

    const shinyMaterial = new MeshStandardMaterial({
      color: 'white',
      roughness: 0.3,
      metalness: 0.3,
      dispose: true,
    });

    const traverseMaterials = (node) => {
      if (node.material) {
        node.material = shinyMaterial;
      }

      if (node.children) {
        node.children.forEach((child) => traverseMaterials(child));
      }
    };

    useEffect(() => {
      traverseMaterials(gltf.scene);
    });

    useFrame(({ clock }) => {
      meshRef.current.rotation.y += 0.005;
    });

    return (
      <mesh ref={meshRef} position={[0, -2.3, 0]}>
        <primitive object={gltf.scene} />
      </mesh>
    );
  }

  return (
    <div className="wrapper">
      {models.map((url) => (
        <div key={url}>
          <Canvas
            shadows
            camera={{ position: [-5, 0.5, 5], fov: 45 }}
            className="sculpt"
            key={url}
            style={{
              width: '500px',
              height: '500px',
              background: 'black',
            }}
          >
            <ambientLight intensity={0.5} />
            <spotLight position={[5, 5, -10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            <Suspense fallback={null}>
              <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
              <Model url={url} />
              <OrbitControls
                enableZoom={false}
                enablePan={false}
                enableRotate={false}
              />
            </Suspense>
          </Canvas>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1em',
              fontWeight: 'bold',
              color: 'lightgrey',
            }}
          >
            {url}
          </p>
        </div>
      ))}
    </div>
  );
}

export default App;
